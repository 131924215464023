'use strict'

const _ = require('lodash')
const platformAppServices = require('./app-services')
const {CSRF_TOKEN} = require('./constants/store')
const loggingUtils = require('./utils/loggingUtils')
const {
    getFedOpsLoggerFactory,
    getFedOpsPlatformLoggers,
    getBiLoggers,
    getBiLoggerFactoriesForApp
} = platformAppServices.bi

const getApi = (appId, pageId, store, instanceId, isRollout) => {
    const fedOpsLoggerFactory = loggingUtils.getFedOpsLoggerFactoryForApp()

    return {
        getCsrfToken: () => store.getValue(CSRF_TOKEN),
        monitoring: _.pick(platformAppServices.monitoring, ['createMonitor', 'getSessionData']),
        bi: loggingUtils.getBiSessionData(),
        biLoggerFactory: loggingUtils.getBiLoggerFactoryForApp({appId, instanceId}),
        fedOpsLoggerFactory: {
            getLoggerForWidget: appFields => fedOpsLoggerFactory.getLoggerForWidget({is_rollout: isRollout, ...appFields})
        },
        fedOpsLoggerCreateFactoryMethod: getFedOpsLoggerFactory(),
        reportTrace: ({actionName = 'noop', tracePosition = 'none', actionDurationMs = 0, message = ''} = {}) => {
            const {pageUrl, getLoadingTime, metaSiteId} = loggingUtils.getBiSessionData()
            loggingUtils.bi.trace({
                appId,
                pageId,
                pageUrl,
                timeFromStartMs: getLoadingTime(),
                actionName,
                actionDurationMs,
                timestampMs: Date.now(),
                tracePosition,
                msid: metaSiteId,
                message
            })
        }
    }
}

module.exports = {
    getApi,
    createMonitor: platformAppServices.monitoring.createMonitor,
    getFedOpsPlatformLoggers,
    getBiLoggers,
    getBiLoggerFactoriesForApp
}
